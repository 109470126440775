

// assistantConstants.js

export const assistantGptModels = [
    "gpt-4-1106-preview",
    // "gpt-4",
    // "gpt-4-0314",
    // "gpt-4-0613",
    // "gpt-3.5-turbo-16k",
    // "gpt-3.5-turbo-16k-0613",
    "gpt-3.5-turbo-1106",
    // "gpt-3.5-turbo",
    // "gpt-3.5-turbo-0613",
    // Add more models as needed
  ];
  